/*
export const LANG = {
    "0": "Generic Error",
    "1": "Invalid service Id",
    "2": "Missing parameters",
    "3": "Invalid payment method",
    "4": "Invalid UUID",
    "5": "Invalid currency code",
    "6": "Invalid country code",
    "7": "Invalid currency for country",
    "8": "Missing rates for currency",
    "9": "Invalid API Key",
    "10": "Invalid ObjectId",
    "11": "No currency for this method",
    "12": "Invalid provider_id",
    "13": "UUID already exists",
    "14": "Provider_id already exists",
    "15": "Invalid Provider Plugin",
    "16": "Invalid Transaction",
    "17": "Transaction already started",
    "18": "Amount must be > 0",
    "19": "Invalid amount for currency",
    "20": "Service Id mismatch",
    "21": "Can't create transaction",
    "22": "Missing signature header",
    "23": "Invalid signature",
    "24": "Invalid provider plugin response",
    "25": "Invalid provider plugin mode",
    "26": "Plugin mode not implemented",
    "27": "Provider Id mismatch",
    "28": "Transaction status is marked as final",
    "29": "Transaction status is the same",
    "30": "Invalid JSON",
    "31": "Invalid Form",
    "32": "Provider plugin response error",
    "33": "Invalid provider plugin signature",
    "34": "Provider plugin timeout",
    "35": "Schema validation error",
    "36": "Transaction expired by provider",
    "37": "Could not connect to provider plugin"
};
*/
export const ERROR_CODES = {
    "CODE_0": {
        "EN": "Generic error",
        "ES": "Error generico "
    },
    "CODE_1": {
        "EN": "Invalid service Id",
        "ES": "Service Id no válido"
    },
    // "CODE_2": {
    //     "EN": "Missing parameters",
    //     "ES": "Faltan parametros"
    // },
    "CODE_2": {
        "EN": "Some of the data entered is invalid",
        "ES": "Algunos de los parámetros ingresados no son válidos"
    },
    "CODE_3": {
        "EN": "Invalid payment method",
        "ES": "Método de pago no válido"
    },
    "CODE_4": {
        "EN": "Invalid Transaction ID",
        "ES": "ID de Transacción no válido"
    },
    "CODE_5": {
        "EN": "Invalid currency code",
        "ES": "Código de moneda no válido"
    },
    "CODE_6": {
        "EN": "Invalid country code",
        "ES": "Código de país no válido"
    },
    "CODE_7": {
        "EN": "Invalid currency for country",
        "ES": "Moneda no válida para este país"
    },
    "CODE_8": {
        "EN": "Missing rates for currency",
        "ES": "Esta moneda no tiene conversiones asociadas"
    },
    "CODE_9": {
        "EN": "Invalid API Key",
        "ES": "API key no válida"
    },
    "CODE_10": {
        "EN": "Invalid ObjectId",
        "ES": "ObjectId no válido"
    },
    "CODE_11": {
        "EN": "Invalid currency for this method",
        "ES": "Moneda no válida para este método de pago"
    },
    "CODE_12": {
        "EN": "Invalid provider_id",
        "ES": "Provider Id no válido"
    },
    "CODE_13": {
        "EN": "UUID already exists",
        "ES": "UUID ya existe"
    },
    "CODE_14": {
        "EN": "Provider_id already exists",
        "ES": "Provider Id ya existe"
    },
    "CODE_15": {
        "EN": "Invalid Provider Plugin",
        "ES": "Plugin de proveedor no válido"
    },
    "CODE_16": {
        "EN": "Invalid Transaction",
        "ES": "Transacción no valida"
    },
    "CODE_17": {
        "EN": "Transaction already started",
        "ES": "Transacción ya fue iniciada"
    },
    "CODE_18": {
        "EN": "Amount must be > 0",
        "ES": "Monto debe ser mayor a 0"
    },
    "CODE_19": {
        "EN": "Invalid amount for currency",
        "ES": "Monto no válido para la moneda"
    },
    "CODE_20": {
        "EN": "Service Id mismatch",
        "ES": "Service Id no corresponde"
    },
    "CODE_21": {
        "EN": "Can't create transaction",
        "ES": "No se pudo crear la transacción"
    },
    "CODE_22": {
        "EN": "Missing signature header",
        "ES": "Falta el header con la signature"
    },
    "CODE_23": {
        "EN": "Invalid signature",
        "ES": "Signature no válida"
    },
    "CODE_24": {
        "EN": "Invalid provider plugin response",
        "ES": "Respuesta de plugin proveedor no válida"
    },
    "CODE_25": {
        "EN": "Invalid provider plugin mode",
        "ES": "Modo de plugin no válido"
    },
    "CODE_26": {
        "EN": "Plugin mode not implemented",
        "ES": "Modo de plugin no implementado"
    },
    "CODE_27": {
        "EN": "Provider Id mismatch",
        "ES": "Provider Id no corresponde"
    },
    "CODE_28": {
        "EN": "Transaction status is marked as final",
        "ES": "El estado de la transacción es final y no se puede modificar"
    },
    "CODE_29": {
        "EN": "Transaction status is the same",
        "ES": "El estado de la transsacion es el mismo"
    },
    "CODE_30": {
        "EN": "Invalid JSON",
        "ES": "JSON inválido"
    },
    "CODE_31": {
        "EN": "Invalid Form",
        "ES": "Form inválido"
    },
    "CODE_32": {
        "EN": "An error has occurred",
        "ES": "Se ha producido un error"
    },
    "CODE_33": {
        "EN": "Invalid provider plugin signature",
        "ES": "Firma del plugin proveedor es inválida"
    },
    "CODE_34": {
        "EN": "Provider plugin timeout",
        "ES": "Tiempo de espera superado"
    },
    "CODE_35": {
        "EN": "Schema validation error",
        "ES": "Error de validación de esquema"
    },
    "CODE_36": {
        "EN": "Transaction expired by provider",
        "ES": "Transacción expirada por el proveedor"
    },
    "CODE_37": {
        "EN": "Could not connect to provider plugin",
        "ES": "No se pudo conectar al plugin proveedor"
    },
    "CODE_38": {
        "EN": "Plugin does not support this country",
        "ES": "Proveedor no soporte este país"
    },
    "CODE_39": {
        "EN": "Invalid request source",
        "ES": "Origen request inválido"
    },
    "CODE_40": {
        "EN": "Can't create resource",
        "ES": "No se pudo crear recurso"
    },
    "CODE_41": {
        "EN": "Invalid Webhook mode",
        "ES": "Modo webhook inválido"
    },
    "CODE_42": {
        "EN": "Invalid template",
        "ES": "Template inválido"
    },
    "CODE_43": {
        "EN": "Can't render template",
        "ES": "No se pudo generar template"
    },
    "CODE_44": {
        "EN": "Invalid source IP",
        "ES": "IP de origen inválida"
    },
    "CODE_45": {
        "EN": "Invalid paid amount",
        "ES": "Monto pagado inferior"
    },
    "CODE_46": {
        "EN": "Error accessing the database service",
        "ES": "Error al acceder a la base de datos"
    },
    "CODE_47": {
        "EN": "Error accessing the cache service",
        "ES": "Error al acceder al servicio de cache"
    },
    "CODE_48": {
        "EN": "Error recreating session",
        "ES": "Error recreando sesión"
    },
    "CODE_49": {
        "EN": "Method not assigned",
        "ES": "Método no asignado"
    },
    "CODE_50": {
        "EN": "Method not assigned for this country",
        "ES": "Método no asignado para este país"
    },
    "CODE_51": {
        "EN": "Could not save PDF",
        "ES": "No se pudo guardar PDF"
    },
    "CODE_52": {
        "EN": "Could not save ticket",
        "ES": "No se pudo guardar ticket"
    },
    "CODE_53": {
        "EN": "Token validation error",
        "ES": "Error validando token"
    },
    "CODE_54": {
        "EN": "Missing API key header",
        "ES": "Header API key no especificado"
    },
    "CODE_55": {
        "EN": "Invalid merchant Id",
        "ES": "Merchant Id inválido"
    },
    "CODE_56": {
        "EN": "Invalid country for method",
        "ES": "País inválido para el método"
    },
    "CODE_57": {
        "EN": "Resource not found",
        "ES": "No existe recurso"
    },
    "CODE_58": {
        "EN": "Error generating JWT",
        "ES": "Error generando JWT"
    },
    "CODE_59": {
        "EN": "Error validating JWT",
        "ES": "Error validando JWT"
    },
    "CODE_60": {
        "EN": "Missing method comissions",
        "ES": "Método de pago no tiene comisiones"
    },
    "CODE_61": {
        "EN": "Invalid account price",
        "ES": "Precio de cuenta inválido"
    },
    "CODE_62": {
        "EN": "Invalid email address",
        "ES": "Dirección de correo no es válida"
    },
    "CODE_63": {
        "EN": "Invalid RUT number",
        "ES": "RUT ingresado no es válido"
    },
    "CODE_64": {
        "EN": "Invalid CPF number",
        "ES": "CPF ingresado no es válido"
    }
};

export const get_error_message = (code, lang = 'EN') => {
    lang = lang.toUpperCase();

    const KEY_NAME = "CODE_" + code;

    if (!ERROR_CODES.hasOwnProperty(KEY_NAME))
        return code

    const tr = ERROR_CODES[KEY_NAME];

    if (lang in tr)
        return tr[lang];

    if ('EN' in tr)
        return tr['EN'];

    return code;
};
