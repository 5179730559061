export const LANG_MESSAGES = {
    'CHOOSE_PAYMENT_TYPE': {
        'EN': 'Choose your payment type',
        'ES': 'Elija el tipo de pago',
        'PT': 'Pagamento'
    },
    'CHOOSE_PAYMENT_METHOD': {
        'EN': 'Choose your payment method',
        'ES': 'Elija el método de pago',
        'PT': 'Pagamento'
    },
    'CHOOSE_ANOTHER_METHOD': {
        'EN': 'Choose another payment method',
        'ES': 'Elige otro método de pago',
        'PT': 'Escolha outra forma de pagamento',
    },
    'TRANSACTION_COMPLETED_SUCCESSFULLY': {
        'EN': 'Transaction completed successfully',
        'ES': 'Pago completado',
        'PT': 'Transação concluída com sucesso'
    },
    'TRANSACTION_COMPLETED_LINE_1': {
        'EN': 'Thank you, your payment has been successful.',
        'ES': 'Gracias, su pago ha sido exitoso.',
        'PT': 'Obrigado, seu pagamento foi efetuado com sucesso.'
    },
    'TRANSACTION_COMPLETED_LINE_2': {
        'EN': 'We have sent a confirmation email to ',
        'ES': 'Hemos enviado un correo electrónico de confirmación a la casilla',
        'PT': 'Enviamos um e-mail de confirmação para '
    },
    'TRANSACTION_HAS_FAILED': {
        'EN': 'Your payment failed',
        'ES': 'Su pago falló',
        'PT': 'Seu pagamento falhou'
    },
    'PLEASE_TRY_AGAIN': {
        'EN': 'Please try again',
        'ES': 'Por favor, inténtelo nuevamente',
        'PT': 'Por favor, tente novamente'
    },
    'PARAMETER_VALIDATION_ERROR': {
        'EN': 'Parameter validation error',
        'ES': 'Error de validación de parámetros',
        'PT': 'Erro de validação de parâmetro'
    },
    'CHECK_YOUR_INTEGRATION': {
        'EN': 'Please check your integration and try again',
        'ES': 'Comprueba su integración e inténtelo nuevamente.',
        'PT': 'Verifique sua integração e tente novamente.'
    },
    'TYPE': {
        'EN': 'Type',
        'ES': 'Tipo',
        'PT': 'Tipo'
    },
    'METHOD': {
        'EN': 'Method',
        'ES': 'Metodo',
        'PT': 'Metodo'
    },
    'LOADING': {
        'EN': 'Loading',
        'ES': 'Cargando',
        'PT': 'Cargando'
    },
    'PERSONAL': {
        'EN': 'Personal',
        'ES': 'Personal',
        'PT': 'Personal'
    },
    'BACK_TO_SITE': {
        'EN': 'Back to merchant site',
        'ES': 'Volver al sitio del comercio',
        'PT': 'Voltar ao site do comerciante'
    },
    'POWERED_BY': {
        'EN': 'Powered by',
        'ES': 'Powered by',
        'PT': 'Powered by'
    },
    'REDIRECTING_TO_METHOD': {
        'EN': 'Redirecting to payment method',
        'ES': 'Redireccionando al método de pago',
        'PT': 'Redireccionando al método de pagamento'
    },
    'CARD': {
        'EN': 'Card',
        'ES': 'Tarjeta',
        'PT': 'Tarjeta'
    },
    'CASH': {
        'EN': 'Cash',
        'ES': 'Efectivo',
        'PT': 'Efectivo'
    },
    'BITCOIN': {
        'EN': 'Bitcoin',
        'ES': 'Bitcoin',
        'PT': 'Bitcoin'
    },
    'TICKET': {
        'EN': 'Ticket',
        'ES': 'Ticket',
        'PT': 'Ticket'
    },
    'BANK_TRANSFER': {
        'EN': 'Bank Transfer',
        'ES': 'Transferencia Bancaria',
        'PT': 'Transferencia Bancaria'
    },
    'CONTINUE': {
        'EN': 'Continue',
        'ES': 'Continuar',
        'PT': 'Continuar'
    },
    'PAY': {
        'EN': 'Pay',
        'ES': 'Pagar',
        'PT': 'Pagar'
    },
    'Phone': {
        'EN': 'Phone',
        'ES': 'Fono',
        'PT': 'Fono'
    },
    'Personal Id': {
        'EN': 'Personal Id',
        'ES': 'Id Personal',
        'PT': 'Id Personal'
    },
    'First Name': {
        'EN': 'First Name',
        'ES': 'Nombre',
        'PT': 'Nombre'
    },
    'Last Name': {
        'EN': 'Last Name',
        'ES': 'Apellido',
        'PT': 'Apellido'
    },
    'Email': {
        'EN': 'Email',
        'ES': 'Correo electronico',
        'PT': 'Correo electronico'
    },
    'Phone number': {
        'EN': 'Phone number',
        'ES': 'Número telefónico',
        'PT': 'Número de telefone'
    },
    'MISSING_SID': {
        'EN': 'Missing Checkout Session',
        'ES': 'Falta sesion checkout',
        'PT': 'Falta sesion checkout'
    },
    'SELECTED_METHOD_UNAVAILBLE': {
        'EN': 'Selected method is not available',
        'ES': 'Metodo seleccionado no disponible',
        'PT': 'Pagamento no disponible'
    },
    'NO_PAYMENT_TYPES_AVAILABLE': {
        'EN': 'No payment types available',
        'ES': 'No hay tipos de pago disponibles',
        'PT': 'No hay tipo de pagamento disponible'
    },
    'NO_PAYMENT_METHODS_AVAILABLE': {
        'EN': 'No payment methods available',
        'ES': 'No hay métodos de pago disponibles',
        'PT': 'No hay método de pagamento no disponible'
    },
    'TRANSACTION_IS_WAITING': {
        'EN': 'We could not confirm the payment',
        'ES': 'No hemos podido confirmar el pago',
        'PT': 'Não foi possível confirmar o pagamento'
    },
    'TRANSACTION_IS_WAITING_MESSAGE': {
        'EN': 'In case you have completed the payment process, we will inform you as soon as our provider notifies us',
        'ES': 'En caso de que hayas completado el proceso de pago, te informaremos en cuanto nuestro proveedor nos notifique',
        'PT': 'Caso você tenha concluído o processo de pagamento, informaremos assim que nosso provedor nos notificar'
    },
    'MISSING_FIELDS': {
        'EN': 'Missing Fields',
        'ES': 'Faltan campos',
        'PT': 'Faltan campos'
    },
    'INVALID_REDIRECT_URL': {
        'EN': 'Invalid redirect URL',
        'ES': 'URL de redirección no válida',
        'PT': 'URL de redireccion no valida'
    },
    'SOMETHING_WENT_WRONG': {
        'EN': 'something went wrong',
        'ES': 'Algo no ha ido bien',
        'PT': 'Algo deu errado'
    },
    'COOKIES_POLICY': {
        'EN': 'Cookie policies',
        'ES': 'Política de cookies',
        'PT': 'Política de cookies'
    },
    'PRIVACY_POLICY': {
        'EN': 'Privacy policies',
        'ES': 'Política de privacidad',
        'PT': 'Política de privacidade'
    }
};

export const get_message = (message, lang = 'EN') => {
    lang = lang.toUpperCase();

    if (!LANG_MESSAGES.hasOwnProperty(message))
        return message

    const tr = LANG_MESSAGES[message];

    if (lang in tr)
        return tr[lang];

    if ('EN' in tr)
        return tr['EN'];

    return message;
};
