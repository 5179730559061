import { Card, Col, Container, Row } from "react-bootstrap"
import { CardBody } from "reactstrap"


export default function NotificationBody({ children }) {
    return (
        <CardBody style={{
            borderTop: 'none'
        }}>
            <Container className="mt-4">
                <Row className="justify-content-md-center">
                    <Col xs={12}>
                        <Card>
                            <CardBody className='p-0 p-md-4' style={{
                                border: 'none'
                            }}>
                                {children}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </CardBody>
    )
}