const ENV = 'prod'

const BASE_URL = ENV === 'prod' ? 'https://gateway.payretailers.cl' : 'https://apinewqa.paygol.com'

const API_URL = ENV === 'prod' ? 'https://www.payretailers.cl' : 'https://apinewqa.paygol.com'

const PAYMENT_WINDOW_URL = ENV === 'prod' ? 'https://paymentwindow.payretailers.cl' : 'https://paymentwindowqa.payretailers.cl'

export {
    ENV,
    BASE_URL,
    API_URL,
    PAYMENT_WINDOW_URL
}
