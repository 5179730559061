import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { PaymentWindow } from "./components/paymentwindow/index";
import { CheckoutWindow } from "./components/checkoutwindow/index";
import { ErrorWindow } from "./components/errorwindow/index";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'
import EmailInvoice from "./components/Products/EmailInvoice/EmailInvoice";
import PaymentLink from "./components/Products/PaymentLink/PaymentLink";

library.add(faLanguage)

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PaymentWindow />} />
        <Route path="/checkout" element={<CheckoutWindow />} />
        <Route path="/email-invoice/:token" element={<EmailInvoice />} />
        <Route path="/payment-link/:token" element={<PaymentLink />} />
        <Route path="/error" element={<ErrorWindow />} />
      </Routes>
    </Router>
  );
}
