const countries = {
    "ES": [
        {
            "name": "Afganistán",
            "code": "AF"
        },
        {
            "name": "Islas Åland",
            "code": "AX"
        },
        {
            "name": "Albania",
            "code": "AL"
        },
        {
            "name": "Argelia",
            "code": "DZ"
        },
        {
            "name": "Samoa Americana",
            "code": "AS"
        },
        {
            "name": "Andorra",
            "code": "AD"
        },
        {
            "name": "Angola",
            "code": "AO"
        },
        {
            "name": "Anguila",
            "code": "AI"
        },
        {
            "name": "Antártida",
            "code": "AQ"
        },
        {
            "name": "Antigua y Barbuda",
            "code": "AG"
        },
        {
            "name": "Argentina",
            "code": "AR"
        },
        {
            "name": "Armenia",
            "code": "AM"
        },
        {
            "name": "Aruba",
            "code": "AW"
        },
        {
            "name": "Australia",
            "code": "AU"
        },
        {
            "name": "Austria",
            "code": "AT"
        },
        {
            "name": "Azerbaiyán",
            "code": "AZ"
        },
        {
            "name": "Bahamas",
            "code": "BS"
        },
        {
            "name": "Bahrein",
            "code": "BH"
        },
        {
            "name": "Bangladés",
            "code": "BD"
        },
        {
            "name": "Barbados",
            "code": "BB"
        },
        {
            "name": "Bielorrusia",
            "code": "BY"
        },
        {
            "name": "Bélgica",
            "code": "BE"
        },
        {
            "name": "Belice",
            "code": "BZ"
        },
        {
            "name": "Benín",
            "code": "BJ"
        },
        {
            "name": "Bermudas",
            "code": "BM"
        },
        {
            "name": "Bután",
            "code": "BT"
        },
        {
            "name": "Bolivia",
            "code": "BO"
        },
        {
            "name": "Bosnia y Herzegovina",
            "code": "BA"
        },
        {
            "name": "Botsuana",
            "code": "BW"
        },
        {
            "name": "Isla Bouvet",
            "code": "BV"
        },
        {
            "name": "Brasil",
            "code": "BR"
        },
        {
            "name": "Territorio Británico del Océano Índico",
            "code": "IO"
        },
        {
            "name": "Brunéi Darussalam",
            "code": "BN"
        },
        {
            "name": "Bulgaria",
            "code": "BG"
        },
        {
            "name": "Burkina Faso",
            "code": "BF"
        },
        {
            "name": "Burundi",
            "code": "BI"
        },
        {
            "name": "Camboya",
            "code": "KH"
        },
        {
            "name": "Camerún",
            "code": "CM"
        },
        {
            "name": "Canadá",
            "code": "CA"
        },
        {
            "name": "Cabo Verde",
            "code": "CV"
        },
        {
            "name": "Islas Caimán",
            "code": "KY"
        },
        {
            "name": "República Centroafricana",
            "code": "CF"
        },
        {
            "name": "Chad",
            "code": "TD"
        },
        {
            "name": "Chile",
            "code": "CL"
        },
        {
            "name": "China",
            "code": "CN"
        },
        {
            "name": "Isla de Navidad",
            "code": "CX"
        },
        {
            "name": "Colombia",
            "code": "CO"
        },
        {
            "name": "Comoras",
            "code": "KM"
        },
        {
            "name": "Congo",
            "code": "CG"
        },
        {
            "name": "Congo, República Democrática del",
            "code": "CD"
        },
        {
            "name": "Islas Cook",
            "code": "CK"
        },
        {
            "name": "Costa Rica",
            "code": "CR"
        },
        {
            "name": "Costa D\"Ivoire",
            "code": "CI"
        },
        {
            "name": "Croacia",
            "code": "HR"
        },
        {
            "name": "Cuba",
            "code": "CU"
        },
        {
            "name": "Chipre",
            "code": "CY"
        },
        {
            "name": "República Checa",
            "code": "CZ"
        },
        {
            "name": "Dinamarca",
            "code": "DK"
        },
        {
            "name": "Yibuti",
            "code": "DJ"
        },
        {
            "name": "Dominica",
            "code": "DM"
        },
        {
            "name": "República Dominicana",
            "code": "DO"
        },
        {
            "name": "Ecuador",
            "code": "EC"
        },
        {
            "name": "Egipto",
            "code": "EG"
        },
        {
            "name": "El Salvador",
            "code": "SV"
        },
        {
            "name": "Guinea Ecuatorial",
            "code": "GQ"
        },
        {
            "name": "Eritrea",
            "code": "ER"
        },
        {
            "name": "Estonia",
            "code": "EE"
        },
        {
            "name": "Etiopía",
            "code": "ET"
        },
        {
            "name": "Islas Malvinas (Falkland Islands)",
            "code": "FK"
        },
        {
            "name": "Islas Feroe",
            "code": "FO"
        },
        {
            "name": "Fiyi",
            "code": "FJ"
        },
        {
            "name": "Finlandia",
            "code": "FI"
        },
        {
            "name": "Francia",
            "code": "FR"
        },
        {
            "name": "Guayana Francesa",
            "code": "GF"
        },
        {
            "name": "Polinesia Francesa",
            "code": "PF"
        },
        {
            "name": "Territorios Australes Franceses",
            "code": "TF"
        },
        {
            "name": "Islas Feroe",
            "code": "FO"
        },
        {
            "name": "Fiyi",
            "code": "FJ"
        },
        {
            "name": "Finlandia",
            "code": "FI"
        },
        {
            "name": "Francia",
            "code": "FR"
        },
        {
            "name": "Guayana Francesa",
            "code": "GF"
        },
        {
            "name": "Polinesia Francesa",
            "code": "PF"
        },
        {
            "name": "Territorios Australes Franceses",
            "code": "TF"
        },
        {
            "name": "Gabón",
            "code": "GA"
        },
        {
            "name": "Gambia",
            "code": "GM"
        },
        {
            "name": "Georgia",
            "code": "GE"
        },
        {
            "name": "Alemania",
            "code": "DE"
        },
        {
            "name": "Ghana",
            "code": "GH"
        },
        {
            "name": "Gibraltar",
            "code": "GI"
        },
        {
            "name": "Grecia",
            "code": "GR"
        },
        {
            "name": "Groenlandia",
            "code": "GL"
        },
        {
            "name": "Granada",
            "code": "GD"
        },
        {
            "name": "Guadalupe",
            "code": "GP"
        },
        {
            "name": "Guam",
            "code": "GU"
        },
        {
            "name": "Guatemala",
            "code": "GT"
        },
        {
            "name": "Guernesey",
            "code": "GG"
        },
        {
            "name": "Guinea",
            "code": "GN"
        },
        {
            "name": "Guinea-Bisáu",
            "code": "GW"
        },
        {
            "name": "Guyana",
            "code": "GY"
        },
        {
            "name": "Haití",
            "code": "HT"
        },
        {
            "name": "Isla Heard e Islas McDonald",
            "code": "HM"
        },
        {
            "name": "Santa Sede (Estado de la Ciudad del Vaticano)",
            "code": "VA"
        },
        {
            "name": "Honduras",
            "code": "HN"
        },
        {
            "name": "Hong Kong",
            "code": "HK"
        },
        {
            "name": "Hungría",
            "code": "HU"
        },
        {
            "name": "Islandia",
            "code": "ES"
        },
        {
            "name": "India",
            "code": "IN"
        },
        {
            "name": "Indonesia",
            "code": "ID"
        },
        {
            "name": "Irán, República Islámica De",
            "code": "IR"
        },
        {
            "name": "Irak",
            "code": "IQ"
        },
        {
            "name": "Irlanda",
            "code": "ES"
        },
        {
            "name": "Isla de Man",
            "code": "IM"
        },
        {
            "name": "Israel",
            "code": "IL"
        },
        {
            "name": "Italia",
            "code": "IT"
        },
        {
            "name": "Jamaica",
            "code": "JM"
        },
        {
            "name": "Japón",
            "code": "JP"
        },
        {
            "name": "Jersey",
            "code": "JE"
        },
        {
            "name": "Jordania",
            "code": "JO"
        },
        {
            "name": "Kazajistán",
            "code": "KZ"
        },
        {
            "name": "Kenia",
            "code": "KE"
        },
        {
            "name": "Kiribati",
            "code": "KI"
        },
        {
            "name": "Corea, República Popular Democrática de",
            "code": "KP"
        },
        {
            "name": "Corea, República de",
            "code": "KR"
        },
        {
            "name": "Kuwait",
            "code": "KW"
        },
        {
            "name": "Kirguistán",
            "code": "KG"
        },
        {
            "name": "República Democrática Popular Lao",
            "code": "LA"
        },
        {
            "name": "Letonia",
            "code": "LV"
        },
        {
            "name": "Líbano",
            "code": "LB"
        },
        {
            "name": "Lesoto",
            "code": "LS"
        },
        {
            "name": "Liberia",
            "code": "LR"
        },
        {
            "name": "Jamahiriya Árabe Libia",
            "code": "LY"
        },
        {
            "name": "Liechtenstein",
            "code": "LI"
        },
        {
            "name": "Lituania",
            "code": "LT"
        },
        {
            "name": "Luxemburgo",
            "code": "LU"
        },
        {
            "name": "Macao",
            "code": "MO"
        },
        {
            "name": "Macedonia, Antigua República Yugoslava de",
            "code": "MK"
        },
        {
            "name": "Madagascar",
            "code": "MG"
        },
        {
            "name": "Malaui",
            "code": "MW"
        },
        {
            "name": "Malasia",
            "code": "MY"
        },
        {
            "name": "Maldivas",
            "code": "MV"
        },
        {
            "name": "Malí",
            "code": "ML"
        },
        {
            "name": "Malta",
            "code": "MT"
        },
        {
            "name": "Islas Marshall",
            "code": "MH"
        },
        {
            "name": "Martinica",
            "code": "MQ"
        },
        {
            "name": "Mauritania",
            "code": "MR"
        },
        {
            "name": "Mauricio",
            "code": "MU"
        },
        {
            "name": "Mayotte",
            "code": "YT"
        },
        {
            "name": "México",
            "code": "MX"
        },
        {
            "name": "Micronesia, Estados Federados de",
            "code": "FM"
        },
        {
            "name": "Moldavia, República de",
            "code": "MD"
        },
        {
            "name": "Mónaco",
            "code": "MC"
        },
        {
            "name": "Mongolia",
            "code": "MN"
        },
        {
            "name": "Monserrat",
            "code": "MS"
        },
        {
            "name": "Marruecos",
            "code": "MA"
        },
        {
            "name": "Mozambique",
            "code": "MZ"
        },
        {
            "name": "Birmania",
            "code": "MM"
        },
        {
            "name": "Namibia",
            "code": "NA"
        },
        {
            "name": "Nauru",
            "code": "NR"
        },
        {
            "name": "Nepal",
            "code": "NP"
        },
        {
            "name": "Países Bajos",
            "code": "NL"
        },
        {
            "name": "Antillas Neerlandesas",
            "code": "AN"
        },
        {
            "name": "Nueva Caledonia",
            "code": "NC"
        },
        {
            "name": "Nueva Zelanda",
            "code": "NZ"
        },
        {
            "name": "Nicaragua",
            "code": "NI"
        },
        {
            "name": "Níger",
            "code": "NE"
        },
        {
            "name": "Nigeria",
            "code": "NG"
        },
        {
            "name": "Niue",
            "code": "NU"
        },
        {
            "name": "Isla Norfolk",
            "code": "NF"
        },
        {
            "name": "Islas Marianas del Norte",
            "code": "MP"
        },
        {
            "name": "Noruega",
            "code": "NO"
        },
        {
            "name": "Omán",
            "code": "OM"
        },
        {
            "name": "Pakistán",
            "code": "PK"
        },
        {
            "name": "Palau",
            "code": "PW"
        },
        {
            "name": "Territorio Palestino, Ocupado",
            "code": "PS"
        },
        {
            "name": "Panamá",
            "code": "PA"
        },
        {
            "name": "Papúa Nueva Guinea",
            "code": "PG"
        },
        {
            "name": "Paraguay",
            "code": "PY"
        },
        {
            "name": "Perú",
            "code": "PE"
        },
        {
            "name": "Filipinas",
            "code": "PH"
        },
        {
            "name": "Pitcairn",
            "code": "PN"
        },
        {
            "name": "Polonia",
            "code": "PL"
        },
        {
            "name": "Portugal",
            "code": "PT"
        },
        {
            "name": "Puerto Rico",
            "code": "PR"
        },
        {
            "name": "Catar",
            "code": "QA"
        },
        {
            "name": "Reunión",
            "code": "RE"
        },
        {
            "name": "Rumanía",
            "code": "RO"
        },
        {
            "name": "Federación Rusa",
            "code": "RU"
        },
        {
            "name": "RUANDA",
            "code": "RW"
        },
        {
            "name": "Santa Elena",
            "code": "SH"
        },
        {
            "name": "San Cristóbal y Nieves",
            "code": "KN"
        },
        {
            "name": "Santa Lucía",
            "code": "LC"
        },
        {
            "name": "San Vicente y las Granadinas",
            "code": "VC"
        },
        {
            "name": "Samoa",
            "code": "WS"
        },
        {
            "name": "San Marino",
            "code": "SM"
        },
        {
            "name": "Santo Tomé y Príncipe",
            "code": "ST"
        },
        {
            "name": "Arabia Saudita",
            "code": "SA"
        },
        {
            "name": "Senegal",
            "code": "SN"
        },
        {
            "name": "Seychelles",
            "code": "SC"
        },
        {
            "name": "Sierra Leona",
            "code": "SL"
        },
        {
            "name": "Singapur",
            "code": "SG"
        },
        {
            "name": "Eslovaquia",
            "code": "SK"
        },
        {
            "name": "Eslovenia",
            "code": "SI"
        },
        {
            "name": "Islas Salomón",
            "code": "SB"
        },
        {
            "name": "Somalia",
            "code": "SO"
        },
        {
            "name": "Sudáfrica",
            "code": "ZA"
        },
        {
            "name": "Islas Georgias del Sur y Sandwich del Sur",
            "code": "GS"
        },
        {
            "name": "España",
            "code": "ES"
        },
        {
            "name": "Sri Lanka",
            "code": "LK"
        },
        {
            "name": "Sudán",
            "code": "SD"
        },
        {
            "name": "Suazilandia",
            "code": "SZ"
        },
        {
            "name": "Suecia",
            "code": "SE"
        },
        {
            "name": "Suiza",
            "code": "CH"
        },
        {
            "name": "República Árabe Siria",
            "code": "SY"
        },
        {
            "name": "Taiwán, Provincia de China",
            "code": "TW"
        },
        {
            "name": "Tayikistán",
            "code": "TJ"
        },
        {
            "name": "Tanzania, República Unida de",
            "code": "TZ"
        },
        {
            "name": "Tailandia",
            "code": "TH"
        },
        {
            "name": "Timor Oriental",
            "code": "TL"
        },
        {
            "name": "Togo",
            "code": "TG"
        },
        {
            "name": "Tokelau",
            "code": "TK"
        },
        {
            "name": "Tonga",
            "code": "TO"
        },
        {
            "name": "Trinidad y Tobago",
            "code": "TT"
        },
        {
            "name": "Túnez",
            "code": "TN"
        },
        {
            "name": "Turquía",
            "code": "TR"
        },
        {
            "name": "Turkmenistán",
            "code": "TM"
        },
        {
            "name": "Islas Turcas y Caicos",
            "code": "TC"
        },
        {
            "name": "Tuvalu",
            "code": "TV"
        },
        {
            "name": "Uganda",
            "code": "GU"
        },
        {
            "name": "Ucrania",
            "code": "UA"
        },
        {
            "name": "Emiratos Árabes Unidos",
            "code": "AE"
        },
        {
            "name": "Reino Unido",
            "code": "GB"
        },
        {
            "name": "Estados Unidos",
            "code": "US"
        },
        {
            "name": "Islas menores alejadas de los Estados Unidos",
            "code": "UM"
        },
        {
            "name": "Uruguay",
            "code": "UY"
        },
        {
            "name": "Uzbekistán",
            "code": "UZ"
        },
        {
            "name": "Vanuatu",
            "code": "VU"
        },
        {
            "name": "Venezuela",
            "code": "VE"
        },
        {
            "name": "Vietnam",
            "code": "VN"
        },
        {
            "name": "Islas Vírgenes Británicas",
            "code": "VG"
        },
        {
            "name": "Islas Vírgenes, EE.UU.",
            "code": "VI"
        },
        {
            "name": "Sáhara Occidental",
            "code": "EH"
        },
        {
            "name": "Yemen",
            "code": "SI"
        },
        {
            "name": "Zambia",
            "code": "ZM"
        },
        {
            "name": "Zimbabue",
            "code": "ZW"
        }
    ],
    "EN": [
        {
            "name": "Afghanistan",
            "code": "AF"
        },
        {
            "name": "Åland Islands",
            "code": "AX"
        },
        {
            "name": "Albania",
            "code": "AL"
        },
        {
            "name": "Algeria",
            "code": "DZ"
        },
        {
            "name": "American Samoa",
            "code": "AS"
        },
        {
            "name": "Andorra",
            "code": "AD"
        },
        {
            "name": "Angola",
            "code": "AO"
        },
        {
            "name": "Anguilla",
            "code": "AI"
        },
        {
            "name": "Antarctica",
            "code": "AQ"
        },
        {
            "name": "Antigua and Barbuda",
            "code": "AG"
        },
        {
            "name": "Argentina",
            "code": "AR"
        },
        {
            "name": "Armenia",
            "code": "AM"
        },
        {
            "name": "Aruba",
            "code": "AW"
        },
        {
            "name": "Australia",
            "code": "AU"
        },
        {
            "name": "Austria",
            "code": "AT"
        },
        {
            "name": "Azerbaijan",
            "code": "AZ"
        },
        {
            "name": "Bahamas",
            "code": "BS"
        },
        {
            "name": "Bahrain",
            "code": "BH"
        },
        {
            "name": "Bangladesh",
            "code": "BD"
        },
        {
            "name": "Barbados",
            "code": "BB"
        },
        {
            "name": "Belarus",
            "code": "BY"
        },
        {
            "name": "Belgium",
            "code": "BE"
        },
        {
            "name": "Belize",
            "code": "BZ"
        },
        {
            "name": "Benin",
            "code": "BJ"
        },
        {
            "name": "Bermuda",
            "code": "BM"
        },
        {
            "name": "Bhutan",
            "code": "BT"
        },
        {
            "name": "Bolivia",
            "code": "BO"
        },
        {
            "name": "Bosnia and Herzegovina",
            "code": "BA"
        },
        {
            "name": "Botswana",
            "code": "BW"
        },
        {
            "name": "Bouvet Island",
            "code": "BV"
        },
        {
            "name": "Brazil",
            "code": "BR"
        },
        {
            "name": "British Indian Ocean Territory",
            "code": "IO"
        },
        {
            "name": "Brunei Darussalam",
            "code": "BN"
        },
        {
            "name": "Bulgaria",
            "code": "BG"
        },
        {
            "name": "Burkina Faso",
            "code": "BF"
        },
        {
            "name": "Burundi",
            "code": "BI"
        },
        {
            "name": "Cambodia",
            "code": "KH"
        },
        {
            "name": "Cameroon",
            "code": "CM"
        },
        {
            "name": "Canada",
            "code": "CA"
        },
        {
            "name": "Cape Verde",
            "code": "CV"
        },
        {
            "name": "Cayman Islands",
            "code": "KY"
        },
        {
            "name": "Central African Republic",
            "code": "CF"
        },
        {
            "name": "Chad",
            "code": "TD"
        },
        {
            "name": "Chile",
            "code": "CL"
        },
        {
            "name": "China",
            "code": "CN"
        },
        {
            "name": "Christmas Island",
            "code": "CX"
        },
        {
            "name": "Colombia",
            "code": "CO"
        },
        {
            "name": "Comoros",
            "code": "KM"
        },
        {
            "name": "Congo",
            "code": "CG"
        },
        {
            "name": "Congo, The Democratic Republic of the",
            "code": "CD"
        },
        {
            "name": "Cook Islands",
            "code": "CK"
        },
        {
            "name": "Costa Rica",
            "code": "CR"
        },
        {
            "name": "Cote D\"Ivoire",
            "code": "CI"
        },
        {
            "name": "Croatia",
            "code": "HR"
        },
        {
            "name": "Cuba",
            "code": "CU"
        },
        {
            "name": "Cyprus",
            "code": "CY"
        },
        {
            "name": "Czech Republic",
            "code": "CZ"
        },
        {
            "name": "Denmark",
            "code": "DK"
        },
        {
            "name": "Djibouti",
            "code": "DJ"
        },
        {
            "name": "Dominica",
            "code": "DM"
        },
        {
            "name": "Dominican Republic",
            "code": "DO"
        },
        {
            "name": "Ecuador",
            "code": "EC"
        },
        {
            "name": "Egypt",
            "code": "EG"
        },
        {
            "name": "El Salvador",
            "code": "SV"
        },
        {
            "name": "Equatorial Guinea",
            "code": "GQ"
        },
        {
            "name": "Eritrea",
            "code": "ER"
        },
        {
            "name": "Estonia",
            "code": "EE"
        },
        {
            "name": "Ethiopia",
            "code": "ET"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "code": "FK"
        },
        {
            "name": "Faroe Islands",
            "code": "FO"
        },
        {
            "name": "Fiji",
            "code": "FJ"
        },
        {
            "name": "Finland",
            "code": "FI"
        },
        {
            "name": "France",
            "code": "FR"
        },
        {
            "name": "French Guiana",
            "code": "GF"
        },
        {
            "name": "French Polynesia",
            "code": "PF"
        },
        {
            "name": "French Southern Territories",
            "code": "TF"
        },
        {
            "name": "Gabon",
            "code": "GA"
        },
        {
            "name": "Gambia",
            "code": "GM"
        },
        {
            "name": "Georgia",
            "code": "GE"
        },
        {
            "name": "Germany",
            "code": "DE"
        },
        {
            "name": "Ghana",
            "code": "GH"
        },
        {
            "name": "Gibraltar",
            "code": "GI"
        },
        {
            "name": "Greece",
            "code": "GR"
        },
        {
            "name": "Greenland",
            "code": "GL"
        },
        {
            "name": "Grenada",
            "code": "GD"
        },
        {
            "name": "Guadeloupe",
            "code": "GP"
        },
        {
            "name": "Guam",
            "code": "GU"
        },
        {
            "name": "Guatemala",
            "code": "GT"
        },
        {
            "name": "Guernsey",
            "code": "GG"
        },
        {
            "name": "Guinea",
            "code": "GN"
        },
        {
            "name": "Guinea-Bissau",
            "code": "GW"
        },
        {
            "name": "Guyana",
            "code": "GY"
        },
        {
            "name": "Haiti",
            "code": "HT"
        },
        {
            "name": "Heard Island and Mcdonald Islands",
            "code": "HM"
        },
        {
            "name": "Holy See (Vatican City State)",
            "code": "VA"
        },
        {
            "name": "Honduras",
            "code": "HN"
        },
        {
            "name": "Hong Kong",
            "code": "HK"
        },
        {
            "name": "Hungary",
            "code": "HU"
        },
        {
            "name": "Iceland",
            "code": "IS"
        },
        {
            "name": "India",
            "code": "IN"
        },
        {
            "name": "Indonesia",
            "code": "ID"
        },
        {
            "name": "Iran, Islamic Republic Of",
            "code": "IR"
        },
        {
            "name": "Iraq",
            "code": "IQ"
        },
        {
            "name": "Ireland",
            "code": "IE"
        },
        {
            "name": "Isle of Man",
            "code": "IM"
        },
        {
            "name": "Israel",
            "code": "IL"
        },
        {
            "name": "Italy",
            "code": "IT"
        },
        {
            "name": "Jamaica",
            "code": "JM"
        },
        {
            "name": "Japan",
            "code": "JP"
        },
        {
            "name": "Jersey",
            "code": "JE"
        },
        {
            "name": "Jordan",
            "code": "JO"
        },
        {
            "name": "Kazakhstan",
            "code": "KZ"
        },
        {
            "name": "Kenya",
            "code": "KE"
        },
        {
            "name": "Kiribati",
            "code": "KI"
        },
        {
            "name": "Korea, Democratic People\"S Republic of",
            "code": "KP"
        },
        {
            "name": "Korea, Republic of",
            "code": "KR"
        },
        {
            "name": "Kuwait",
            "code": "KW"
        },
        {
            "name": "Kyrgyzstan",
            "code": "KG"
        },
        {
            "name": "Lao People\"S Democratic Republic",
            "code": "LA"
        },
        {
            "name": "Latvia",
            "code": "LV"
        },
        {
            "name": "Lebanon",
            "code": "LB"
        },
        {
            "name": "Lesotho",
            "code": "LS"
        },
        {
            "name": "Liberia",
            "code": "LR"
        },
        {
            "name": "Libyan Arab Jamahiriya",
            "code": "LY"
        },
        {
            "name": "Liechtenstein",
            "code": "LI"
        },
        {
            "name": "Lithuania",
            "code": "LT"
        },
        {
            "name": "Luxembourg",
            "code": "LU"
        },
        {
            "name": "Macao",
            "code": "MO"
        },
        {
            "name": "Macedonia, The Former Yugoslav Republic of",
            "code": "MK"
        },
        {
            "name": "Madagascar",
            "code": "MG"
        },
        {
            "name": "Malawi",
            "code": "MW"
        },
        {
            "name": "Malaysia",
            "code": "MY"
        },
        {
            "name": "Maldives",
            "code": "MV"
        },
        {
            "name": "Mali",
            "code": "ML"
        },
        {
            "name": "Malta",
            "code": "MT"
        },
        {
            "name": "Marshall Islands",
            "code": "MH"
        },
        {
            "name": "Martinique",
            "code": "MQ"
        },
        {
            "name": "Mauritania",
            "code": "MR"
        },
        {
            "name": "Mauritius",
            "code": "MU"
        },
        {
            "name": "Mayotte",
            "code": "YT"
        },
        {
            "name": "Mexico",
            "code": "MX"
        },
        {
            "name": "Micronesia, Federated States of",
            "code": "FM"
        },
        {
            "name": "Moldova, Republic of",
            "code": "MD"
        },
        {
            "name": "Monaco",
            "code": "MC"
        },
        {
            "name": "Mongolia",
            "code": "MN"
        },
        {
            "name": "Montserrat",
            "code": "MS"
        },
        {
            "name": "Morocco",
            "code": "MA"
        },
        {
            "name": "Mozambique",
            "code": "MZ"
        },
        {
            "name": "Myanmar",
            "code": "MM"
        },
        {
            "name": "Namibia",
            "code": "NA"
        },
        {
            "name": "Nauru",
            "code": "NR"
        },
        {
            "name": "Nepal",
            "code": "NP"
        },
        {
            "name": "Netherlands",
            "code": "NL"
        },
        {
            "name": "Netherlands Antilles",
            "code": "AN"
        },
        {
            "name": "New Caledonia",
            "code": "NC"
        },
        {
            "name": "New Zealand",
            "code": "NZ"
        },
        {
            "name": "Nicaragua",
            "code": "NI"
        },
        {
            "name": "Niger",
            "code": "NE"
        },
        {
            "name": "Nigeria",
            "code": "NG"
        },
        {
            "name": "Niue",
            "code": "NU"
        },
        {
            "name": "Norfolk Island",
            "code": "NF"
        },
        {
            "name": "Northern Mariana Islands",
            "code": "MP"
        },
        {
            "name": "Norway",
            "code": "NO"
        },
        {
            "name": "Oman",
            "code": "OM"
        },
        {
            "name": "Pakistan",
            "code": "PK"
        },
        {
            "name": "Palau",
            "code": "PW"
        },
        {
            "name": "Palestinian Territory, Occupied",
            "code": "PS"
        },
        {
            "name": "Panama",
            "code": "PA"
        },
        {
            "name": "Papua New Guinea",
            "code": "PG"
        },
        {
            "name": "Paraguay",
            "code": "PY"
        },
        {
            "name": "Peru",
            "code": "PE"
        },
        {
            "name": "Philippines",
            "code": "PH"
        },
        {
            "name": "Pitcairn",
            "code": "PN"
        },
        {
            "name": "Poland",
            "code": "PL"
        },
        {
            "name": "Portugal",
            "code": "PT"
        },
        {
            "name": "Puerto Rico",
            "code": "PR"
        },
        {
            "name": "Qatar",
            "code": "QA"
        },
        {
            "name": "Reunion",
            "code": "RE"
        },
        {
            "name": "Romania",
            "code": "RO"
        },
        {
            "name": "Russian Federation",
            "code": "RU"
        },
        {
            "name": "RWANDA",
            "code": "RW"
        },
        {
            "name": "Saint Helena",
            "code": "SH"
        },
        {
            "name": "Saint Kitts and Nevis",
            "code": "KN"
        },
        {
            "name": "Saint Lucia",
            "code": "LC"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "code": "VC"
        },
        {
            "name": "Samoa",
            "code": "WS"
        },
        {
            "name": "San Marino",
            "code": "SM"
        },
        {
            "name": "Sao Tome and Principe",
            "code": "ST"
        },
        {
            "name": "Saudi Arabia",
            "code": "SA"
        },
        {
            "name": "Senegal",
            "code": "SN"
        },
        {
            "name": "Seychelles",
            "code": "SC"
        },
        {
            "name": "Sierra Leone",
            "code": "SL"
        },
        {
            "name": "Singapore",
            "code": "SG"
        },
        {
            "name": "Slovakia",
            "code": "SK"
        },
        {
            "name": "Slovenia",
            "code": "SI"
        },
        {
            "name": "Solomon Islands",
            "code": "SB"
        },
        {
            "name": "Somalia",
            "code": "SO"
        },
        {
            "name": "South Africa",
            "code": "ZA"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "code": "GS"
        },
        {
            "name": "Spain",
            "code": "ES"
        },
        {
            "name": "Sri Lanka",
            "code": "LK"
        },
        {
            "name": "Sudan",
            "code": "SD"
        },
        {
            "name": "Swaziland",
            "code": "SZ"
        },
        {
            "name": "Sweden",
            "code": "SE"
        },
        {
            "name": "Switzerland",
            "code": "CH"
        },
        {
            "name": "Syrian Arab Republic",
            "code": "SY"
        },
        {
            "name": "Taiwan, Province of China",
            "code": "TW"
        },
        {
            "name": "Tajikistan",
            "code": "TJ"
        },
        {
            "name": "Tanzania, United Republic of",
            "code": "TZ"
        },
        {
            "name": "Thailand",
            "code": "TH"
        },
        {
            "name": "Timor-Leste",
            "code": "TL"
        },
        {
            "name": "Togo",
            "code": "TG"
        },
        {
            "name": "Tokelau",
            "code": "TK"
        },
        {
            "name": "Tonga",
            "code": "TO"
        },
        {
            "name": "Trinidad and Tobago",
            "code": "TT"
        },
        {
            "name": "Tunisia",
            "code": "TN"
        },
        {
            "name": "Turkey",
            "code": "TR"
        },
        {
            "name": "Turkmenistan",
            "code": "TM"
        },
        {
            "name": "Turks and Caicos Islands",
            "code": "TC"
        },
        {
            "name": "Tuvalu",
            "code": "TV"
        },
        {
            "name": "Uganda",
            "code": "UG"
        },
        {
            "name": "Ukraine",
            "code": "UA"
        },
        {
            "name": "United Arab Emirates",
            "code": "AE"
        },
        {
            "name": "United Kingdom",
            "code": "GB"
        },
        {
            "name": "United States",
            "code": "US"
        },
        {
            "name": "United States Minor Outlying Islands",
            "code": "UM"
        },
        {
            "name": "Uruguay",
            "code": "UY"
        },
        {
            "name": "Uzbekistan",
            "code": "UZ"
        },
        {
            "name": "Vanuatu",
            "code": "VU"
        },
        {
            "name": "Venezuela",
            "code": "VE"
        },
        {
            "name": "Viet Nam",
            "code": "VN"
        },
        {
            "name": "Virgin Islands, British",
            "code": "VG"
        },
        {
            "name": "Virgin Islands, U.S.",
            "code": "VI"
        },
        {
            "name": "Western Sahara",
            "code": "EH"
        },
        {
            "name": "Yemen",
            "code": "YE"
        },
        {
            "name": "Zambia",
            "code": "ZM"
        },
        {
            "name": "Zimbabwe",
            "code": "ZW"
        },
        {
            "name": "Uganda",
            "code": "GU"
        },
        {
            "name": "Ucrania",
            "code": "UA"
        },
        {
            "name": "Emiratos Árabes Unidos",
            "code": "AE"
        },
        {
            "name": "Reino Unido",
            "code": "GB"
        },
        {
            "name": "Estados Unidos",
            "code": "US"
        },
        {
            "name": "Islas menores alejadas de los Estados Unidos",
            "code": "UM"
        },
        {
            "name": "Uruguay",
            "code": "UY"
        },
        {
            "name": "Uzbekistán",
            "code": "UZ"
        },
        {
            "name": "Vanuatu",
            "code": "VU"
        },
        {
            "name": "Venezuela",
            "code": "VE"
        },
        {
            "name": "Vietnam",
            "code": "VN"
        },
        {
            "name": "Islas Vírgenes Británicas",
            "code": "VG"
        },
        {
            "name": "Islas Vírgenes, EE.UU.",
            "code": "VI"
        },
        {
            "name": "Sáhara Occidental",
            "code": "EH"
        },
        {
            "name": "Yemen",
            "code": "SI"
        },
        {
            "name": "Zambia",
            "code": "ZM"
        },
        {
            "name": "Zimbabue",
            "code": "ZW"
        }
    ]
}

export {
    countries
}