import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import PoweredBy from './common/PoweredBy';
import { BASE_URL } from '../config'
import SandboxBadge from './common/SandboxBadge'

export default function Body({ children, isLoaded=true }) {
    return (
        <Container className='mt-4 p-4'>

            {!/gateway/.test(BASE_URL) && (<SandboxBadge />)}

            <Row className='justify-content-md-center'>
                <Col xs={12} sm={10} md={7} lg={6} xl={5}>
                    <div className="card text-center">
                        {children}
                    </div>
                </Col>
            </Row>

            {isLoaded && <PoweredBy />} 

        </Container>
    )
}
