import React from 'react'

export default function DetailsItem({
    title, value
}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            fontSize: '13px'
        }}>
            <div style={{
                textTransform: 'uppercase',
                marginTop: '.5rem',
                fontWeight: '600'
            }}>{title}</div>

            <div>{value}</div>
        </div>
    )
}
