import { Stack } from "react-bootstrap";

export default function PoweredBy({
    variant = 'white'
}) {
    return (
        <Stack gap={1} className="col-md-12 mx-auto text-center m-4" direction="horizontal" style={{
            justifyContent: "center"
        }}>
            <div className="header__security__text">Secured by</div>

            <a href="https://payretailers.cl" target="_blank" rel="noopener noreferrer">
                <img
                    src={`https://cdn.payretailers.cl/images/payretailers/logo-payretailers-1.png`}
                    style={{
                        maxWidth: '100px'
                    }}
                    alt="PayRetailers"
                />
            </a>
        </Stack>
    )
}
