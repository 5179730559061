import React from 'react'
import { Alert } from 'react-bootstrap'


export default function ErrorMessage({
    message,
    heading = '',
    code = ''
}) {
    return (
        <Alert
            variant="danger"
            className='text-center'
            style={{
                background: 'inherit'
            }}
        >
            {heading && (<Alert.Heading className='h6'>{heading}</Alert.Heading>)}

            <p style={{
                fontSize: '11px'
            }}>{message}</p>

            {code && (<small style={{
                position: 'absolute',
                top: '5px',
                right: '5px'
            }}>({code})</small>)}
        </Alert>
    )
}
