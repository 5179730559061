import { useContext } from 'react';
import { PaymentContext } from "./context";
import { get_error_message } from '../../lang/index';
import ErrorMessage from '../common/ErrorMessage';
import { get_message } from '../../lang/messages';
import NotificationBody from './NotificationBody';


const PaymentWindowNotifications = () => {
    const { error } = useContext(PaymentContext).merror;
    const { session } = useContext(PaymentContext).msession;
    const { lang } = useContext(PaymentContext).mlang;

    if (session === null) {
        if (error === '')
            return '';

        return (
            <NotificationBody>
                <ErrorMessage
                    heading={get_message('SOMETHING_WENT_WRONG', lang)}
                    message={error}
                />
            </NotificationBody>
        )
    }

    if (session.code !== null && session.code !== '' && session.code !== undefined) {
        if (session.error_message !== "" && session.error_message !== null)
            return (
                <NotificationBody>
                    <ErrorMessage
                        heading={get_message('SOMETHING_WENT_WRONG', lang)}
                        message={`...${session.error_message}`}
                    />
                </NotificationBody>
            );

        return (
            <NotificationBody>
                <ErrorMessage
                    heading={get_message('SOMETHING_WENT_WRONG', lang)}
                    message={get_error_message(session.code, lang)}
                />
            </NotificationBody>
        )
    }

    if (error === '')
        return '';

    return (
        <NotificationBody>
            <ErrorMessage
                heading={get_message('SOMETHING_WENT_WRONG', lang)}
                message={error}
            />
        </NotificationBody>
    );
}

export { PaymentWindowNotifications };
