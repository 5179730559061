export default function Price({
    amount, currency
}) {
    if (!amount || !currency) {
        return ''
    }

    return (
        new Intl.NumberFormat(
            'es-CL',
            { style: 'currency', currency: currency }
        ).format(amount)
    )
}
