import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { BASE_URL, PAYMENT_WINDOW_URL } from '../../../config'
import Ticket from '../Ticket'

export default function PaymentLink() {
    const { token } = useParams()

    const [loading, setLoading] = useState(true)

    const [link, setLink] = useState()

    const [logo, setLogo] = useState()

    const [inputs, setInputs] = useState({
        pg_invoice_uid: token,
        pg_serviceid: '',
        pg_currency: '',
        pg_name: '',
        pg_custom: '',
        pg_price: '',
        pg_language: '',
        pg_return_url: '',
        pg_cancel_url: '',
    })

    const getDocumentInfo = useCallback(() => {
        fetch(`${BASE_URL}/webcheckout/links/${token}`, { method: 'GET' })
            .then((response) => response.json())
            .then((response) => {
                return response
            })
            .then(({ links, contacts }) => {
                const regex = new RegExp(/(http|https)/)

                if (links) {
                    links.customer_service = contacts ? contacts.filter(c => /cliente/i.test(c.title))[0] : ''

                    const custom_log = links.logo_file ? (regex.test(String(links.logo_file)) ? links.logo_file : `https://cdn.payretailers.cl/merchant/logos/${links.logo_file}`) : ''

                    setLogo(custom_log)

                    const return_url = `${PAYMENT_WINDOW_URL}/payment-link/${token}?ok`
                    const cancel_url = `${PAYMENT_WINDOW_URL}/payment-link/${token}?nok`

                    setInputs({
                        ...inputs,
                        pg_serviceid: links.service_id,
                        pg_currency: links.currency,
                        pg_language: links.language ? links.language : 'ES',
                        pg_name: links.title,
                        pg_custom: links.details,
                        pg_price: links.amount,
                        pg_return_url: return_url,
                        pg_cancel_url: cancel_url
                    })

                    setLink(links)
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }, [inputs, token])

    useEffect(() => {
        getDocumentInfo()

        document.body.classList.remove('pg-blue1')
        document.body.classList.add('pg-white')

        // eslint-disable-next-line
    }, [token])

    return (
        <Ticket
            invoice={link}
            inputs={inputs}
            logo={logo}
            loading={loading}
            is_payment_link={true}
        />
    )
}
