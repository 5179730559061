import React from 'react'
import { Alert } from 'react-bootstrap'


export default function InfoMessage({
    message,
    heading = ''
}) {
    return (
        <Alert
            variant="info"
            className='text-center'
            style={{
                background: 'inherit'
            }}
        >
            {heading && (<Alert.Heading className='h5'>{heading}</Alert.Heading>)}

            <p>{message}</p>
        </Alert>
    )
}
