export const getStorageValue = (key, defaultValue) => {  
    try
    {
        const saved = localStorage.getItem(key);
        const initial = JSON.parse(saved);
        return initial || defaultValue;
    }
    catch (e)
    {
        return defaultValue;
    }
};

export const setStorageValue = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageValue = (key) => {
    try
    {
        localStorage.removeItem(key);
    }
    catch(e) {}
};
