import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './index.css'

import { CookiesProvider } from 'react-cookie';

import './i18n'
import BackdropWrapper from './components/common/BackdropWrapper';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Suspense fallback={<BackdropWrapper />}>
        <App />
      </Suspense>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
