import React, { useState, useEffect } from 'react'
import { Container, Row, ThemeProvider, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from '../../config'
import { ST_EXPIRED, ST_PAID } from '../../enums/PaymentLink'
import BackdropWrapper from '../common/BackdropWrapper'
import PoweredBy from '../common/PoweredBy'
import SandboxBadge from '../common/SandboxBadge'
import NotFound from './NotFound'

export default function Ticket({
    invoice,
    inputs,
    items = [],
    logo = null,
    loading = true,
    is_payment_link = true
}) {
    const { t } = useTranslation()

    const [isPaid, setIsPaid] = useState(false)

    const [isExpired, setIsExpired] = useState(false)

    useEffect(() => {
        setIsPaid(invoice?.status === ST_PAID)
        setIsExpired(invoice?.status === ST_EXPIRED || (new Date(invoice?.due_date) < (new Date())))
    }, [invoice?.status, invoice?.due_date])

    return (
        < ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        >
            {loading && (<BackdropWrapper />)}

            {!/gateway/.test(BASE_URL) && (<SandboxBadge />)}

            {
                !loading && invoice ? (<Container className='mt-4'>
                    <Row className="justify-content-center">
                        <Col xs={11} md={10} lg={7} style={{
                            height: '40px'
                        }} className='pg-gradient90' />
                    </Row>

                    <Row className="justify-content-center">
                        <Col xs={11} md={10} lg={7} className="my-4 p-2">
                            {logo && (
                                <img
                                    style={{
                                        maxWidth: '300px',
                                        maxHeight: '150px'
                                    }}
                                    src={`${logo}`}
                                    alt={invoice.merchant_full_name ? invoice.merchant_full_name : 'PayRetailers'}
                                />
                            )}

                            {!logo && (<img src='https://cdn.payretailers.cl/images/payretailers/logo-payretailers-1.png'
                                alt='PayRetailers'
                                style={{
                                    maxWidth: '200px'
                                }} />)}
                        </Col>
                    </Row>

                    <Row className="justify-content-center" style={{
                        fontSize: '14px'
                    }}>
                        <Col xs={11} md={10} lg={7}>
                            <Row>
                                <Col xs={11} md={8}>
                                    <Row className="my-2">
                                        <Col xs={12} className="fw-bold">
                                            {t('Seller\'s information')}
                                        </Col>
                                        <Col xs={12} style={{
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {invoice.fantasy_name ? invoice.fantasy_name : invoice.company_name}
                                        </Col>
                                        <Col xs={12} style={{
                                            whiteSpace: 'nowrap',
                                        }}>
                                            <i>{invoice?.customer_service?.email ? invoice?.customer_service?.email : (invoice.merchant_email ? invoice.merchant_email : '--')}</i>
                                        </Col>
                                    </Row>

                                    {!is_payment_link && invoice.client_email ? (<Row className="mt-4">
                                        <Col xs={12} className="fw-bold">
                                            {t('Recipient\'s email')}
                                        </Col>
                                        <Col xs={12} style={{
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {invoice.client_email}
                                        </Col>
                                    </Row>) : ''}
                                </Col>

                                <Col xs={11} md={4}>
                                    {!is_payment_link ? (<Row className="my-2">
                                        <Col xs={12} className="fw-bold">
                                            {t('Date of issue')}
                                        </Col>
                                        <Col xs={12} style={{
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {invoice.created_at && (new Intl.DateTimeFormat('es-CL').format(new Date(invoice.created_at)))}
                                        </Col>
                                    </Row>) : ''}

                                    {!is_payment_link && invoice.due_date ? (<Row className="mt-4">
                                        <Col xs={12} className="fw-bold">
                                            {t('Due date')}
                                        </Col>
                                        <Col xs={12} style={{
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {new Intl.DateTimeFormat('es-CL').format(new Date(invoice.due_date))}
                                        </Col>
                                    </Row>) : ''}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} style={{
                                    height: '50px'
                                }}></Col>
                            </Row>

                            <Row className="border rounded-top rounded-start">
                                <Col xs={12}>
                                    {invoice.details && (<Row>
                                        <Col xs={12} className="mt-3 fw-bold">
                                            {t('Details')}
                                        </Col>

                                        <Col xs={12} className="my-2 prevent_word_overflow">
                                            {invoice.details}
                                        </Col>
                                    </Row>)}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={6}></Col>
                                <Col xs={12} md={2} className="fw-bold text-end my-4" style={{
                                    fontSize: '18px'
                                }}>
                                    {t('Total')}:
                                </Col>
                                <Col xs={12} md={4} className="fw-bold text-end py-4 border-start border-end border-bottom rounded-bottom">
                                    {new Intl.NumberFormat('es-CL', { style: 'currency', currency: invoice.currency }).format(invoice.amount)}
                                </Col>
                            </Row>

                            <Row className="justify-content-center no-print">
                                <Col xs={5} md={4} className="text-center py-4 d-grid gap-2">
                                    <Button type="button" onClick={() => window.print()} variant='light'>{t('Print')}</Button>
                                </Col>

                                <Col xs={5} md={4} className="text-center py-4">
                                    <Form action={
                                        is_payment_link || (!isPaid && !isExpired) ? (!/gateway/.test(BASE_URL) ? 'https://apinewqa.paygol.com/pay' : 'https://gateway.payretailers.cl/pay') : '#'
                                    } method="POST">
                                        {!is_payment_link && (!isPaid && !isExpired) ? (<input type="hidden" name="pg_invoice_id" value={inputs.pg_invoice_uid} />) : ''}
                                        {is_payment_link && (<input type="hidden" name="pg_link_id" value={inputs.pg_invoice_uid} />)}
                                        {is_payment_link || (!isPaid && !isExpired) ? (<input type="hidden" name="pg_serviceid" value={inputs.pg_serviceid} />) : ''}
                                        <input type="hidden" name="pg_currency" value={inputs.pg_currency} />
                                        {inputs.pg_name && (<input type="hidden" name="pg_name" value={inputs.pg_name} />)}
                                        {inputs.pg_custom && (<input type="hidden" name="pg_custom" value={inputs.pg_custom} />)}
                                        <input type="hidden" name="pg_price" value={inputs.pg_price} />
                                        {inputs.pg_language && (<input type="hidden" name="pg_language" value={inputs.pg_language} />)}
                                        {inputs.pg_return_url && (<input type="hidden" name="pg_return_url" value={inputs.pg_return_url} />)}
                                        {inputs.pg_cancel_url && (<input type="hidden" name="pg_cancel_url" value={inputs.pg_cancel_url} />)}

                                        <Row>
                                            <Col className="d-grid gap-2">
                                                <Button
                                                    type="submit"
                                                    className="btn-blue1"
                                                    variant='primary'
                                                    disabled={!is_payment_link && (isPaid || isExpired)}
                                                >{t('Pay')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                            {!is_payment_link && isPaid ? (<Row className="text-center" style={{
                                color: '#ce1314'
                            }}>
                                {invoice.language === 'ES' && (<Col>Este <b>Cobro</b> ya se encuentra pagado</Col>)}
                                {invoice.language === 'EN' && (<Col>This <b>Email Invoice</b> has already been paid</Col>)}
                            </Row>) : ''}

                            {!is_payment_link && isExpired ? (<Row className="text-center" style={{
                                color: '#ce1314'
                            }}>
                                {invoice.language === 'ES' && (<Col>Este <b>Cobro</b> ha expirado</Col>)}
                                {invoice.language === 'EN' && (<Col>This <b>Email Invoice</b> has expired</Col>)}
                            </Row>) : ''}
                        </Col>
                    </Row>

                    <PoweredBy variant='default' />
                </Container>) : !invoice ? <NotFound /> : ''
            }
        </ThemeProvider >
    )
}
