import { BASE_URL } from "../../../config";

const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Max-Age': 86400
};

const APIPostRequest = (url, data, custom_headers = {}) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...headers, ...custom_headers },
        body: JSON.stringify(data)
    };

    return fetch(`${BASE_URL}${url}`, requestOptions)
        .then(res => res.json());
};

const APIGetRequest = (url, custom_headers = {}) => {
    const requestOptions = {
        method: 'GET',
        headers: { ...headers, ...custom_headers }
    };

    return fetch(`${BASE_URL}${url}`, requestOptions)
        .then(res => res.json());
};

const ValidatePayment = (payment, src, token) => {
    let custom_headers = {
        'X-CSRF-TOKEN': token
    };

    return APIPostRequest("/webcheckout/validate/" + src, payment, custom_headers);
};

const ChangeCountry = (payment, token) => {
    let custom_headers = {
        'X-CSRF-TOKEN': token
    };

    return APIPostRequest("/webcheckout/methods", payment, custom_headers);
};

const LoadSession = (sid, token) => {
    let custom_headers = {
        'X-CSRF-TOKEN': token
    };

    return APIGetRequest("/webcheckout/load/" + sid, custom_headers);
};

const CheckoutPaymentInfo = (uuid, token) => {
    let custom_headers = {
        'X-CSRF-TOKEN': token
    };

    return APIGetRequest("/webcheckout/info/" + uuid, custom_headers);
};

const CheckoutPaymentStatus = (uuid, token) => {
    let custom_headers = {
        'X-CSRF-TOKEN': token
    };

    return APIGetRequest("/webcheckout/status/" + uuid, custom_headers);
};

export {
    ValidatePayment,
    ChangeCountry,
    LoadSession,
    CheckoutPaymentInfo,
    CheckoutPaymentStatus
}
