const ST_CREATED = 1
const ST_PAID = 2
const ST_SENT = 3
const ST_EXPIRED = 4

const currencies = {
    CLP: 'CLP',
    USD: 'USD',
    EUR: 'EUR'
}

const status = {
    1: 'Created',
    2: 'Paid',
    3: 'Sent',
    4: 'Expired',
}

export {
    ST_CREATED,
    ST_PAID,
    ST_SENT,
    ST_EXPIRED,
    currencies,
    status
}